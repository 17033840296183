<template>
  <div class="search_container">
    <div class="header">
      <van-nav-bar
        title="筛选案例"
        left-arrow
        @click-left="handleBack"
        safe-area-inset-top
      >
      </van-nav-bar>
    </div>
    <!-- <form action="/">
      <van-search
        v-model="searchVal"
        placeholder="输入案例名称搜索"
        @search="onSearch"
        @cancel="onCancel"
      >
      </van-search>
    </form> -->
    <form action="/">
      <van-search
        v-model="searchVal"
        show-action
        placeholder="输入案例名称搜索"
        @search="onSearch"
        @cancel="onCancel"
      />
    </form>
    <article class="mycase-article">
      <van-list>
        <van-cell v-for="item in searchCase" :key="item.caseId">
          <div class="mycase-box-info">
            <div class="case-box">
              <div class="cover-img">
                <img
                  :src="
                    item.headImgUrl ? `${imgUrl}/${item.headImgUrl}` : morenImg
                  "
                  alt=""
                  @click="loadToDetails(item.caseId)"
                />
              </div>
              <div class="case-info">
                <div class="title">
                  <p>{{ item.title }}</p>
                  <p>
                    {{ item.typeName }}| {{ item.area }}m² |
                    {{ item.casePosition }}
                  </p>
                </div>
                <div class="share-del">
                  <div class="share" @click="handleShare(item)"></div>
                  <div
                    class="del"
                    v-if="caseType === 1"
                    @click="handleDelCase(item.caseId)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="my_case" v-if="caseType === 1">
            <div>
              <img src="../../assets/icons/file-text.png" alt="" /><span
                >线索{{ item.countOfClues }}
              </span>
            </div>
            <div>
              <img src="../../assets/icons/eye.png" alt="" /><span
                >查看{{ item.countOfViews }}</span
              >
            </div>
            <div>
              <img src="../../assets/icons/share.png" alt="" /><span
                >转发{{ item.countOfForward }}
              </span>
            </div>
          </div>
        </van-cell>
      </van-list>
    </article>
<!-- 删除 -->
    <div class="del-wrapper">
      <van-overlay :show="showDel" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p>删除</p>
            <p>确定删除该案例？</p>
            <van-grid clickable :column-num="2">
              <van-grid-item @click="handleConfirm('cancel')"
                ><span>取消</span></van-grid-item
              >
              <van-grid-item @click="handleConfirm"
                ><span>确定</span></van-grid-item
              >
            </van-grid>
          </div>
        </div>
      </van-overlay>
    </div>
    <!-- 弹出分享框 -->
    <div class="share_box">
      <van-share-sheet
        v-model:show="showShare"
        title="分享"
        :options="options"
        cancel-text="取消"
        @select="onSelect"
        safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { findCaseByPageApp, findMyCaseByPage, deleteCase, } from "../../api/case";
import { shareCaseToMiniProgram } from "@/utils/commonUtil";
import { caseReprint } from "@/api/common";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      showDel:false,
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      morenImg: require("../../assets/icons/default-coverimg.png"),
      searchCase: {},
      searchVal: route.query.searchVal ? route.query.searchVal : "",
      caseType: Number(route.query.caseType),
      showShare: false,
      caseItem: {},
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
      ],
    });

    if (state.searchVal !== "") {
      if (state.caseType === 0) {
        findCaseByPageApp({
          isMaterial: 1,
          search: state.searchVal,
          size: 50,
        }).then((result) => {
          state.searchCase = result.records;
        });
      } else {
        findMyCaseByPage({ search: state.searchVal, size: 50 }).then(
          (result) => {
            state.searchCase = result.records;
          }
        );
      }
    }

    const handleBack = () => {
      router.push({
        name: "caseIndex",
        params: {
          caseType: route.query.caseType,
          twoType: route.query.twoType,
          caseTypeStr: route.query.caseTypeStr,
        },
      });
    };

    //打开分享微信
    const handleShare = (item) => {
      state.showShare = true;
      state.caseId = item.caseId;
      state.caseItem = item;
    };
    //微信分享
    const onSelect = (index) => {
      caseReprint({ busiId: state.caseId });
      // if (index.name === "微信") {
      //   shareCaseToMiniProgram(state.caseItem);
      // }
      shareCaseToMiniProgram(state.caseItem, index);
    };
    const onSearch = async () => {
      if (state.caseType === 0) {
        let result = await findCaseByPageApp({
          isMaterial: 1,
          search: state.searchVal,
          size: 30,
        });
        state.searchCase = result.records;
      } else {
        let res = await findMyCaseByPage({ search: state.searchVal, size: 30 });
        state.searchCase = res.records;
      }
    };

    //取消
    const onCancel = () => {
      state.searchVal = "";
    };
    const loadToDetails = (caseId) => {
      router.push({
        path: "/caseVisitors/caseDetails",
        query: {
          caseId: caseId,
          caseType: state.caseType,
          comfrom: "search",
          searchVal: state.searchVal,
          twoType: route.query.twoType,
        },
      });
    };

     //删除
    const handleDelCase = (id) => {
      state.showDel = !state.showDel;
      state.caseId = id;
    };
    //确认删除
    const handleConfirm = async (isDel) => {
      if (isDel === "cancel") {
        state.showDel = !state.showDel;
      } else {
        let result = await deleteCase(state.caseId);
        if (result) {
          state.showDel = !state.showDel
          onSearch()
        }
      }
    };
    return {
      handleDelCase,
      handleConfirm,
      ...toRefs(state),
      loadToDetails,
      onCancel,
      onSearch,
      handleBack,
      handleShare,
      onSelect,
    };
  },
};
</script>

<style lang="scss">
.search_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }
  .van-search__action {
    color: #ff6e01;
  }
  .Is-comto-material {
    font-size: 14px;
    .comto-material {
      font-weight: 600;
      padding: 18px;
      text-align: left;
    }
    .select-comin {
      .selectBox {
        float: left;
        color: #999999;
        width: 52px;
        height: 28px;
        border: 1px solid #999999;
        line-height: 28px;
        margin-left: 18px;
        border-radius: 4px;
        box-sizing: border-box;
      }
      .bgColor {
        color: #fff;
        background-color: #289fff;
        border: none;
      }
    }
  }
  .mycase-article {
    flex: 1;
    overflow-x: hidden;
    //  margin-bottom: 16px;
    .van-cell {
      padding: 0;
    }
    .van-cell::after {
      border: none;
    }
    .mycase-box-info {
      flex: 1;
      padding: 5px 16px;
      .case-box {
        font-size: 16px;
        text-align: left;
        .cover-img {
          height: 160px;
          > img {
            display: block;
            border-radius: 4px;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .case-info {
          //  height: auto;
          position: relative;
          display: flex;
          width: 343px;
          justify-content: space-between;
          .title {
            //  float: left;
            position: relative;
            width: 330px;
            > p {
              margin: 0;
              padding: 0;
            }
            > p:nth-child(1) {
              font-weight: 600;
              margin-top: 8px;
            }
            > p:nth-child(2) {
              font-weight: 400;
              font-size: 14px;
              margin: 4px 0;
              color: #666666;
            }
          }
          .share-del {
            top: 10px;
            position: relative;
            .share {
              height: 20px;
              width: 20px;
              background: url("../../assets/icons/share-3.png") no-repeat;
            }
            .del {
              height: 20px;
              margin-top: 15px;
              width: 20px;
              background: url("../../assets/icons/trash-2.png") no-repeat;
            }
          }
        }
      }
    }
    .my_case {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 34px;
      border-bottom: 1px solid #f1f3f6;
      border-top: 1px solid #f1f3f6;
      font-size: 12px;
      > div {
        height: 34px;
        display: flex;
        color: #999999;
        align-items: center;
        img {
          margin-right: 3px;
        }
      }
    }
  }
  //  删除
  .del-wrapper {
    .van-overlay {
      z-index: 102;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        font-size: 14px;
        width: 303px;
        height: 133px;
        border-radius: 10px;
        color: #333333;
        background-color: #fff;
        > div {
          > div:nth-child(1) {
            .van-hairline {
              border-radius: 0 0 0 10px;
              padding: 0;
              color: #999999;
            }
          }
          > div:nth-child(2) {
            .van-hairline {
              border-radius: 0 0 10px 0;
              padding: 0;
            }
            [class*="van-hairline"]::after {
              border-right: none;
            }
          }
        }
        [class*="van-hairline"]::after {
          border-bottom: none;
        }
        > p {
          font-weight: 600;
          font-size: 14px;
          margin: 17px 16px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 46px;
          // height: 100%;
          // width: 100%;
        }
      }
    }
  }
  //   分享
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      //    position: relative;
      //    transform: translateX(50%);
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
}
</style>
